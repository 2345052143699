<template>
  <div :class="$style.protocol">
    <div :class="$style.header">
      <img :class="$style.logo" :src="oss + '/manage/media/assets/logo.png'" alt="" />
      <BjSelect
        v-model="type"
        :class="$style.select"
        key-field="id"
        placeholder="请选择"
        value-field="id"
        label-field="name"
        show-all
        :options="option"
      />
    </div>
    <div :class="$style.content">
      <p :class="$style.title">北鲸软件产品使用协议</p>
      <p :class="$style.time">最近更新日期：2021年09月10日</p>
      <p :class="$style.desc">
        为使用北鲸软件服务（以下简称“本服务”），您应当阅读并遵守《北鲸软件产品使用协议》（以下简称“本协议”）以及专项规则等。
        请您务必审慎阅读、充分理解各条款内容，特别是免除或限制责任的相应条款，以及开通或使用某项服务的单独协议，
        并选择接受或不接受。
        <span :class="$style.black">限制、免责条款</span>
        可能以
        <span>加粗形式</span>
        提示您注意。
      </p>
    </div>
    <div :class="$style.content">2222</div>
  </div>
</template>

<script>
export default {
  name: "protocol",
  data() {
    return {
      option: [
        {
          name: "北鲸软件产品使用协议",
          id: "1",
        },
      ],
      type: "1",
    };
  },
  created() {
    this.type = this.$route.query.type;
  },
};
</script>

<style lang="less" module>
.protocol {
  width: 100vw;
  height: 100vh;
  background: #fafafa;

  p {
    margin-bottom: 0;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 0 120px;
    background: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);

    :global {
      .ant-form-item {
        margin-bottom: 0;
      }
    }

    .logo {
      width: 125px;
      height: 30px;
    }

    .select {
      width: 200px;
    }
  }

  .content {
    max-width: 1200px;
    height: calc(100vh - 100px);
    margin: 20px 120px;
    padding: 30px;
    background: #fff;

    .title {
      color: #000;
      font-size: 30px;
      text-align: center;
    }

    .time {
      margin: 30px 0 20px 0;
      color: #5c5c5c;
      font-size: 12px;
      text-align: right;
    }

    .desc {
      color: #5c5c5c;
      font-size: 14px;
    }

    .black {
      color: #000;
      font-weight: bold;
    }
  }
}
</style>
